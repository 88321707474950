@import "variables";

@mixin common-sizing {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  border-radius: 0.5rem;
  color: #fff;
  @include font("Roboto");
}

@mixin state-based-style($background-color, $border-color, $text-color) {
  background: $background-color;
  color: $text-color;
  border: 0.0625rem solid $border-color;
  box-shadow: 0rem 0.0625rem 0.125rem $btn-box-shadow;
}

@mixin size-based-dimensions(
  $paddingTopDown,
  $paddingLeftRight,
  $width,
  $height,
  $fontSize,
  $lineHeight
) {
  padding: $paddingTopDown $paddingLeftRight;
  font-size: $fontSize;
  line-height: $lineHeight;
}

@mixin add-box-shadow($color) {
  box-shadow: 0rem 0.0625rem 0.125rem $btn-box-shadow,
    0rem 0rem 0rem 0.25rem $color;
}

@mixin flex($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

// Drop down
@mixin optionsAndTitle($border, $width, $border-radius, $box-shadow) {
  border: $border;
  width: $width;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
}

//Fonts
@mixin font($font-name) {
  @if $font-name == "Montserrat" {
    font-family: "Montserrat-Regular";
  } @else if $font-name == "Roboto-Medium" {
    font-family: "Roboto-Medium";
  } @else if $font-name == "Montserrat-Medium" {
    font-family: "Montserrat-Medium";
  } @else {
    font-family: "Roboto-Regular";
  }
}

@mixin font-text-style($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}
