/* You can add global styles to this file, and also import other style files */
@import "default";

@font-face {
  font-family: "Roboto-Regular";
  @import "mixins";
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "GoogleSans-Regular";
  src: url("./assets/fonts/Google/Google-Sans.ttf");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
