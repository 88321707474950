@import "mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
@include font("Montserrat");
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background: #f2f4f7;
    border-radius: 0.4688rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #98a2b3;
    border-radius: 0.4688rem;
  }
  :focus-visible {
    outline: none;
  }
}

input:focus,
textarea:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: $primary-btn;

  @include font("Montserrat");
    @include font-text-style(0.875rem, 500, 1.25rem);
}

ul {
  list-style: none;
}

.display-block {
  display: block;
}
.color-black {
  color: #101828;
}

.width-100 {
  width: 100%;
}

.content-area {
  position: fixed;
  .content {
    max-height: 90vh;
    // padding-bottom: 1rem;
    overflow-y: auto;
  }
  @include flex(row, unset, unset);
}

.asset-team-container {
  overflow-y: auto;
  padding: 2rem;
  .ticket-status {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .asset-status {
    width: 100%;
  }
  .dash-section-wrapper {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 0.0625rem solid #eaecf0;
    padding: 1.5rem;
    box-shadow: 0rem 0.0625rem 0.1875rem #1018281a,
      0rem 0.0625rem 0.125rem #1018280f;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    position: relative;

    &.container-with-no-border {
      border: none;
      box-shadow: none;
      margin-bottom: -1.5rem;
    }
    &.loader {
      padding: 0;
      .loader-container {
        width: 100%;
        border-collapse: collapse;
        border-radius: 0.5rem;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        z-index: 9;
        .loader-logo {
          width: 6.25rem;
        }
      }
    }
  }
}

.badge {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.125rem 0.5rem 0.125rem 0.375rem;
  border-radius: 1rem;

  @include font("Montserrat");
  @include font-text-style(0.75rem, 500, 1.125rem);
  .badge-dot {
    display: inline-block;
    width: 0.375rem;
    height: 0.375rem;
    margin-right: 0.4375rem;
    border-radius: 0.5rem;
  }
  &.badge-ongoing {
    color: #64b5f5;
    background: #f4fafe;
    .badge-dot {
      background: #64b5f5;
    }
  }
  &.badge-on-hold {
    color: #F1B10C;
    background: #FCF9EE;
    .badge-dot {
      background:#F1B10C;
    }
  }
  &.badge-available,
  &.badge-closed {
    color: #027a48;
    background: #ecfdf3;
    .badge-dot {
      background: #027a48;
    }
  }
  &.badge-assigned,
  &.badge-todo {
    color: #b54708;
    background: #fdecd8;
    .badge-dot {
      background: #b54708;
    }
  }
  &.badge-out-for-repair {
    color: #f04438;
    background: #fcf1ee;
    .badge-dot {
      background: #f04438;
    }
  }
  &.disabled {
    color: #667085;
    background: #f2f4f7;
    .badge-dot {
      background: #667085;
    }
  }
}

// Modal Global Styling
.modal-header {
  padding: 1.875rem 1.875rem 0;
  @include flex(row, center, space-between);
  position: relative;
  &.loader {
    padding: 0;
    .loader-container {
      width: 100%;
      border-collapse: collapse;
      border-radius: 0.5rem;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      z-index: 9;
      .loader-logo {
        width: 6.25rem;
      }
    }
  }
  &.is-view-ticket {
    @include flex(row, flex-start, flex-start);
    height: 100%;
    padding-bottom: 1.875rem;
    .view-ticket-content {
      min-width: 60%;
    }
    .vertical-divider {
      width: 0.0625rem;
      height: 100%;
      margin: 0 2rem 0 0;
      background: #eaecf0;
    }
    .right-section {
      height: 100%;
      min-width: calc(40% - 2.0625rem);
      @include flex(column, "", space-between);
      .header {
        @include flex(column, flex-start, flex-start);
        h3 {
          margin-bottom: 2rem;
        }
        .comments {
          margin-bottom: 1rem;
          max-width: 30rem;
          .name {
            color: #1d2939;
            @include font("Montserrat-Medium");
            @include font-text-style(0.875rem, 500, 1.5rem);
          }
          .comment {
            color: #344054;
            word-break: break-all;
            margin-top: 0.5rem;
            @include font-text-style(0.875rem, 400, 1rem);
          }
        }
        button {
          align-self: flex-end;
          padding: 0;
        }
      }
      h3 {
        color: #000000;
        @include font-text-style(1rem, 400, 1.5rem);
      }
      .input-section {
        form {
          @include flex(row, flex-start, flex-start);
          width: 100%;
          gap: 0.75rem;
          margin-top: 2rem;
        }
      }
    }
  }
  .left-section {
    min-width: calc(60%);
    .headings {
      @include flex(row, center, space-between);
      padding-right: 2rem;
      h3 {
        color: #000000;
        @include font-text-style(1rem, 500, 2.25rem);
      }
      p {
        margin-top: 0.25rem;
        color: #1d2939;
        @include font-text-style(1rem, 400, 1rem);
      }
    }
  }
  .modal-header-with-alert-icon {
    @include flex(row, flex-start, flex-start);
    &.add-gap {
      gap: 1.5625rem;
    }
  }
  .modal-alert-icon {
    width: 3.625rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    @include flex(row, center, center);
    &.success {
      background: #d1fadf;
    }
    &.danger {
      background: #fee4e2;
    }
  }
  .modal-heading {
    @include flex(column, flex-start, flex-start);
    .modal-header-title {
      margin: 0;
      font-style: normal;
      color: #000000;
@include font("Montserrat");
      @include font-text-style(1.875rem, 400, 2.25rem);
      &.secondary {
        @include font-text-style(1.25rem, 500, 1.875rem);
      }
    }

    .modal-header-subtitle {
      margin-top: 0.5rem;
      font-style: normal;
      color: #969697;

      @include font("Montserrat");
            @include font-text-style(0.75rem, 500, 1rem);
    }
  }
  .modal-header-action {
    align-self: flex-start;
    border: none;
    outline: none;
    cursor: pointer;
    padding: calc(0.5rem);
    border-radius: 50%;
    background-color: #fff;
  }
}

.modal-body {
  text-align: left;
  -ms-overflow-style: none;
  overflow: auto;
  padding: 1rem 1.375rem 0 1.875rem;
  max-height: 75%;
  &.ticket-generate-alert-box,
  &.add-edit-asset-box {
    padding: 1rem 1.375rem 1.375rem 1.875rem;
  }
  &.max-height {
    max-height: unset;
    height: calc(100vh - 5.625rem - 5rem);
  }
  &.no-overflow {
    overflow: unset;
  }
}

.modal-footer {
  width: 100%;
  padding: 1rem 1.375rem 0 1.875rem;
  .button {
    float: right;
    margin-bottom: 1.5rem;
  }
}

.popup-form {
  width: 100%;
  // margin-top: 2rem;
  .hidden-drop-down {
    display: none;
  }
}
.display-block .dropdown-wrapper,
.dropdown-wrapper {
  margin-bottom: 1.5rem;
}
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(0.25rem);
  overflow: hidden;
}

.backdrop-side-drawer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 70%;
}

.center-dialog-box {
  width: 41rem;
  background-color: #fff;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  animation: common-animation ease 500ms;
  &.open {
    display: block;
  }
}
.dialog-box {
  height: 100%;
  width: 50%;
  background-color: #fff;
  position: absolute !important;
  top: 0rem;
  right: 0rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  animation-name: openAnimation;
  animation-duration: 1s;
}

.download-upload-dialog {
  height: 100%;
  width: 50%;
  background-color: #fff;
  position: absolute !important;
  top: 0rem;
  right: 0rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  animation-name: open-upload-download;
  animation-duration: 1s;
}
.close-dialog {
  height: 100%;
  width: 50%;
  background-color: #fff;
  position: absolute !important;
  top: 0rem;
  right: 0rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  animation-name: closeAnimation;
  animation-duration: 1s;
}
.close-upload-download-dialog {
  height: 100%;
  width: 50%;
  background-color: #fff;
  position: absolute !important;
  top: 0rem;
  right: 0rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  animation-name: close-upload-download;
  animation-duration: 1s;
}

.toaster-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0 0 0.375rem;
  box-shadow: 0 0 0.75rem #999;
  color: #fff;
  width: 18rem;
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 0.5rem;
  @include font-text-style(0.875rem, 400, 1.25rem);

  &.toast-success {
    color: #027a48;
    background: #ecfdf3 !important;
    border: 0.0625rem solid #b5edd5;
    background-image: url("../../../../shared/assets/src/lib/20x20/checkbox.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 1.5rem !important;
    background-position: 0.7rem center !important;
  }
  &.toast-error {
    color: #b42318;
    background: #fffbfa !important;
    border: 0.0625rem solid #fda29b;
    background-image: url("../../../../shared/assets/src/lib/24x24/alert-circle.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 1.5rem !important;
    background-position: 0.7rem center !important;
  }
}

.ticket-class {
  display: inline-block;
  vertical-align: middle;
  width: 41rem;
  background-color: #fff;
  position: absolute !important;
  border-radius: 0.5rem;
  border: 0.0625rem solid #eff0f2;
  box-shadow: 0.0625rem 0.0625rem 0.125rem #00000099,
    0 0.0625rem 0.1875rem #0000001a;
  &.open {
    display: block;
  }
}

.tooltip-container {
  text-align: center;
  z-index: 1;
  padding: 0.5rem;
  font-size: 0.66rem;
  font-weight: 400;
  background-color: white;
  width: auto;
  color: #667085;
  border: 0.0675rem solid #667085;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

// Keyframes
@keyframes common-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes openAnimation {
  1% {
    left: 100%;
    top: 0rem;
  }
  60% {
    left: 50%;
    top: 0rem;
  }
}
@keyframes closeAnimation {
  1% {
    left: 50%;
    top: 0rem;
  }
  100% {
    left: 100%;
    top: 0rem;
  }
}
@keyframes open-upload-download {
  1% {
    left: 100%;
    top: 0rem;
  }
  60% {
    left: 54%;
    top: 0rem;
  }
}
@keyframes close-upload-download {
  1% {
    left: 54%;
    top: 0rem;
  }
  100% {
    left: 100%;
    top: 0rem;
  }
}

.app-asset-type-detail  :focus-visible {
  outline: #ffff solid 0px;
}

.app-accessory-type-detail  :focus-visible {
  outline: #ffff solid 0px;
}

.app-user-details  :focus-visible {
  outline: #ffff solid 0px;
}
