// Theme
$coditas-blue: #2c74d6;

// Button component variables
$theme: #071251;

$primary-btn: #2196f3;
$secondary-btn: #e9f4fe;
$tertiary-btn: #ffffff;

$primary-btn-disabled: #c0d5f3;
$secondary-btn-disabled: #c0d5f3;
$tertiary-btn-disabled: #ffffff;

$primary-btn-active-border: #2196f3;
$secondary-btn-active-border: #f9f5ff;
$tertiary-btn-active-border: #d0d5dd;

$primary-btn-disabled-border: #c0d5f3;
$secondary-btn-disabled-border: #c0d5f3;
$tertiary-btn-disabled-border: #eaecf0;

$primary-btn-text: #ffffff;
$secondary-btn-text: #2196f3;
$tertiary-btn-text: #667085;

$secondary-btn-text-disabled: #64b5f5;
$tertiary-btn-text-disabled: #d0d5dd;

$primary-btn-hover-border: #f4ebff;
$secondary-btn-hover-border: #f4ebff;
$tertiary-btn-hover-border: #f2f4f7;

$primary-btn-active: #c0d5f3;
$secondary-btn-active: #f4ebff;
$tertiary-btn-active: #f9fafb;

$btn-box-shadow: #1018280d;

// Text Field variables
$icon-color: #667085;

$text-field: #ffffff;
$text-field-border: #d0d5dd;
$text-field-box-shadow: #1018280d;

$placeholder-text-color: #98a2b3;

//Sign Up Page
$error-color: #e400ff;
$sign-up-card: #00000033;

// Sign in page
$heading-color: #101828;
$subheading-color: #667085;
$subheading-button-color: #2c74d6;
$subheading-button-hover-color: #5d93df;

// Login Screen
$error-message-color: #101828;
$error-description-color: #667085;

// employee dashboard
$employee-background-color: #fcfcfd;
$white-color: #ffffff;
$box-shadow-one-color: #1018281a;
$box-shadow-second-color: #1018280f;
$border-color: #eaecf0;
$border-secondary-color: #d0d5dd;
$button-color: #2196f3;
$background-color: #e9f4fe;
$text-primary-color: #475467;
$text-secondary-color: #344054;
